.recommended-card .d-flex img{
    padding: 0 1px;
    width: 100%;
}
.recommended-card img{
    width: 100%;
}
.ill{
    font-size: 14px;
    color:#000 ;
    font-weight: 600;
}
.article{
    font-size: 12px;
    font-weight: 400;
    color: #798998;
}