*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    
}

.container{
    background-color: rgb(234, 234, 234);
}
.profile{
    display: flex;
    margin-top: -6rem;
    z-index: 999999;
    position: relative;
    align-items: center;
    justify-content: center;
    
}
.dp{
    height: 8rem;
    width: 8rem;
    background-color: chartreuse;
    border-radius: 50%;
}
.detail{
    display: flex;
    flex-direction: column;
    text-align: center;
}

.aside .stats .d-flex {
    border-bottom: 1px solid #0c0c0c34;
}
.aside .stats p{
    color:#20B7F7;
    font-weight: 600;
}
.aside .stats .d-flex:hover{
    border-bottom: 1px solid #20B7F7;;
    color: #20B7F7;;
}

.detail .btn-secondary{
color: #20B7F7;
background-color: #E4F6FE;
border: none;
}