
.menu-link{
    display: grid;
    grid-template-columns: 10rem 3fr 10rem;
    
}
.menu-link ul{
    grid-column: 2/3;
    height: 3rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #ced4da;
    margin-top: 2rem;
    border-radius: 1rem;
    box-shadow: 10px 5px 15px -10px;
    padding-left: 0rem;
}
.menu-link ul li{
    list-style: none;
    text-decoration: none;
}
.menu-link ul li a{
    text-decoration: none;
    color: #6c757d;
    background-color: rgba(255, 255, 255, 0);
    padding: 5px 12px;
    border-radius: 5px;
}
.menu-link ul li a:hover{
    padding: 5px 12px;
    border-radius: 5px;
    color: white;
    background-color: #20b7f7;
}

@media screen and (max-width: 776px){

    .menu-link{
        display: grid;
        grid-template-columns: 3fr ;
        
    }
    .menu-link ul{
        grid-column: 1/3;
        padding-left: 0rem;
    }
  
}
    


