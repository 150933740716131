.people  h6{
      margin-bottom: 0;
        font-size: 14px;
        font-weight: 700;
      }
.interest h6{
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 700;
}
#people h4{
    color: #858585;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 21px;
    margin-top: 2rem;
}
#people .follow-btn{
    border: none;
    background: rgba(183, 209, 249, 0.21);
    color: #3177E0;
    padding: 5px 0;
    border-radius: 8px;
    box-shadow: 1px 1px 1px -5px;
}