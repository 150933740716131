.img-r{
    width: 100%;
   
}
.pagination{
    background-color: azure;
}
 .swiper-button-next{
    background-color: rgba(0, 0, 0, 0.299);
    color: white;
    padding: 2rem;
    border-radius: 50%;
    font-size: 1rem;
}
.swiper-button-prev {
    background-color: rgba(0, 0, 0, 0.299);
    color: white;
    padding: 2rem;
    border-radius: 50%;
    top: 5rem;
  
}