.course-cover{
    font-size: .9rem;
    font-weight: 600;
    border-radius: 10px;
    box-shadow: 10px 15px 10px -15px;
    margin-bottom: 1rem;
}
small {
    color: #8299b2;
    font-weight: 300;
    margin-left: 5px;
    font-family: "Manrope", sans-serif;

}
.feature-footer p{
    color: #8299b2;
    font-weight: 300;
    margin-left: 5px;
    font-family: "Manrope", sans-serif;

}
.course-para{
    font-weight: 300;
    margin: 5px;
    font-family: "Manrope", sans-serif;
}
.feature-footer{
    padding: 5px;
    font-size: .8rem;
    font-weight: 600;
}