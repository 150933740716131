.article-img-cover{
    position: relative;
}
.dots{
    top: .5rem;
    right: .5rem;
   position: absolute;
   z-index: 9999;
}
.overlay{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    overflow: hidden;
    background: linear-gradient(180deg, #000000 -20.65%, rgba(0, 0, 0, 0) 48.7%);
    border-radius: 10px;
}
@media screen and (max-width: 992px){
    .overlay{
        position: relative;
        overflow: hidden;
    }
    .article-img{
        width: 100%;
    }
    
}